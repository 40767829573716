/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useContext,
  useEffect,
  // useRef,
  useState,
  useMemo,
} from 'react';

import * as Sentry from '@sentry/react';

import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { FiltersContext } from 'context/GlobalFiltersContext';
import { AuthContext } from 'context/AuthContext';
import { getLocalAuthStorage } from 'utils/localStorage';

import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
import ReportPage from 'containers/ReportPage';

import Accordion from 'components/Accordion';
import Conditional from 'components/Conditional';
import { Preview } from 'components/Preview';
import InformativeTooltip from 'components/InformativeTooltip';
import { Loading } from 'components/Loading';
import { PageWrapper } from 'components/PageWrapper';
// import { InvisibleDownloadLink } from 'components/InvisibleDowloadLink';

import { PageHeader } from 'components/PageHeader';
//import { ReportContainer } from 'components/ReportContainer';
import { ReportContainerTabs } from 'components/ReportContainerTabs';
import { ReportContainerColumn } from 'components/ReportContainer/subcomponents/ReportContainerColumn';
import { ReportContainerFields } from 'components/ReportContainer/subcomponents/ReportContainerFields';
import { ReactComponent as EmailAtentionIcon } from 'assets/icons/atention-icon.svg';

import { LineSeparator } from 'components/ReportContainer/subcomponents/LineSeparator';
import { ReportContainerFillFields } from 'components/ReportContainer/subcomponents/ReportContainerFillFields';
import ReportTable from 'components/Tables/ReportTable';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as EmailSuccessIcon } from 'assets/icons/rel-success-icon.svg';
import { ReactComponent as RelSendError } from 'assets/icons/error-send-rel-modal-icon.svg';
import { DownloadButton } from 'components/Tables/ReportTable/styles';

import { FieldRow } from 'components/Form/subcomponents/FieldRow';
import { FormGroup } from 'components/Form/subcomponents/FormGroup';
import { Radio } from 'components/Form/subcomponents/Radio';
import { Switch } from 'components/Form/subcomponents/Switch';
// import { Checkbox } from 'components/Form/subcomponents/Checkbox';
import { GridColumn } from 'components/Grid/GridColumn';
import { DateRangePicker } from 'components/Form/subcomponents/DateRangePicker';
import MultiSelect from 'components/Form/subcomponents/MultiSelect';
import ModalMultiSelect from 'components/Form/subcomponents/ModalMultiSelect';

import { Toast } from 'components/Toast';
import { Button } from 'components/Button';
import { FilterModal } from 'components/FilterModal';

import { FiltersService, SavedFiltersService, OccurrenceService, ReportService } from 'services/reports';

import { formatDateRangeValue } from 'utils/formatDateRangeValue';
import { createErrorToast, createGenericErrorToast, createToast } from 'utils/createToast';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { colors } from 'assets/styled/tokens';
import { Spinner } from 'components/Spinner';
import { GenericTooltip } from 'components/Tooltip/GenericTooltip';
import {
  SendOcurrenceEmailSuccessModal,
  SenderrorEmailSuccessModal,
  AtentionModal,
  StyledFormRadio,
  StyleDivRadio,
  StyledRadio,
  // StyledOnlyCurrentCorrectionStatus
  Header,
  GoBackButton,
  EmailNotVerifiedWarningContainer,
  StatusInfo,
  StatusWarningInfo,
  StyledWarningIcon,
  RedoReportButton,
} from './styled';
import { MultiCheckbox } from 'components/Form/subcomponents/MultiCheckbox';

const reportName = 'occurrence';

const Occurrence = ({ pathname }) => {
  const { authContext } = useContext(AuthContext);
  const { user, token } = authContext;
  const authStorage = getLocalAuthStorage();
  const [duplicatedReport, setDuplicatedReport] = useState(false);
  const { globalComponentsContext, setGlobalComponentsContext } = useContext(GlobalComponentsContext);
  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const { isLoading } = globalComponentsContext;
  const history = useHistory();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const { handleSubmit, register, reset, watch, control, getValues, setValue, errors } = useForm({
    defaultValues: {
      unitGroups: headerFilters?.unitGroups?.id ? [headerFilters?.unitGroups?.id] : [],
      unitSubgroups: headerFilters?.unitSubgroups?.id ? [headerFilters?.unitSubgroups?.id] : [],
      units: headerFilters?.units?.id ? [headerFilters?.units?.id] : [],
      states: headerFilters?.states?.id ? [headerFilters?.states?.id] : [],
      showOnlyOpenOccurrences: false,
      showAuditOccurrence: false,
      showOnlyDelayedOccurrences: false,
      reportType: 'pdf',
      status: {
        // Analisada, Não analisada e Solucionada
        occurrences: ['1', '2', '3'] || [],
      },
      occurrences: {
        showOccurrencesPictures: true,
        sla: true,
      },
      corrections: {
        show: 'all',
        correctionTime: true,
        signatures: true,
        deadline: true,
        costsAndMaterials: true,
        correctionPictures: true,
      },
      metrics: {
        metricsForEachOccurrence: true,
        summaryOfAllOccurrences: true,
      },
      reportPeriod: 'reportOpen',
    },
  });

  const [currentSavedFilter, setCurrentSavedFilter] = useState();
  const [toastList, setToastList] = useState([]);
  const [isSendOcurrenceEmailSuccess, setIsSendOcurrenceEmailSuccess] = useState(false);
  const [isSendOcurrenceEmailError500, setIsSendOcurrenceEmailError500] = useState(false);
  const [isSendOcurrenceEmailError429, setIsSendOcurrenceEmailError429] = useState(false);
  const [isSendOcurrenceEmailError422, setIsSendOcurrenceEmailError422] = useState(false);
  const [active, setActive] = useState(false);
  const [modalOptions, setModalOptions] = useState({
    open: false,
    propertyKey: null,
  });

  const [fields, setFields] = useState({
    unitGroups: [],
    unitSubgroups: [],
    states: [],
    systems: [],
    units: [],
    showOnlyOpenOccurrences: false,
    showAuditOccurrence: false,
    showOnlyDelayedOccurrences: false,
    openingOfOccurrenceDateRange: {
      start: new Date(),
      end: new Date(),
    },
    correctionRegisterDateRange: {
      start: new Date(),
      end: new Date(),
    },
    executionLimitDateRange: {
      start: new Date(),
      end: new Date(),
    },
    status: {
      occurrences: [],
      corrections: [],
      // occurrenceNumbers: []
    },
    people: {
      departments: [],
      providers: [],
      executors: [],
      requesters: [],
    },
    local: {
      areaGroups: [],
      areaSubgroups: [],
      areas: [],
      equipmentTypes: [],
      equipments: [],
    },
    prioritization: {
      priorities: [],
      slas: [],
    },
    generatedReports: [],
  });

  const formatPayloadExcel = values => {
    const { period } = values;
    const correctionRegisterDateRange = formatDateRangeValue(period?.correctionRegister);

    const executionLimitDateRange = formatDateRangeValue(period?.executionLimit);

    const openingOfOccurrenceDateRange = formatDateRangeValue(period?.openingOfOccurrence);
    const newUnits =
      values?.units.length === fields.units.length ? (values?.units.length > 99 ? [] : values?.units) : values?.units;
    return {
      ...values,
      materials: values?.multiCheckbox?.some(option => option?.label?.includes('Materiais')),
      companyId: headerFilters?.companies?.id,
      units: newUnits?.map(unit => Number(unit)),
      unitGroups: values?.unitGroups?.map(unitGroup => Number(unitGroup)),
      unitSubgroups: values?.unitSubgroups?.map(unitSubgroup => Number(unitSubgroup)),
      states: fields?.states.filter(state => values.states.includes(state.value)).map(state => state.value),
      systems: fields?.systems.filter(system => values.systems.includes(`${system.value}`)).map(system => system.value),
      period: {
        correctionRegisterDateRange,
        executionLimitDateRange,
        openingOfOccurrenceDateRange,
      },
      status: {
        ...values.status,
        occurrences: fields?.status?.occurrences
          .filter(occurrence => values.status.occurrences.includes(`${occurrence.value}`))
          .map(occurrence => occurrence.value),
      },
    };
  };

  const formatPayload = values => {
    const { period } = values;
    const correctionRegisterDateRange = formatDateRangeValue(period?.correctionRegister);

    const executionLimitDateRange = formatDateRangeValue(period?.executionLimit);

    const openingOfOccurrenceDateRange = formatDateRangeValue(period?.openingOfOccurrence);
    const newUnits =
      values?.units.length === fields.units.length ? (values?.units.length > 99 ? [] : values?.units) : values?.units;
    return {
      ...values,
      companyId: headerFilters?.companies?.id,
      units: newUnits?.map(unit => Number(unit)),
      unitGroups: values?.unitGroups?.map(unitGroup => Number(unitGroup)),
      unitSubgroups: values?.unitSubgroups?.map(unitSubgroup => Number(unitSubgroup)),
      states: fields?.states.filter(state => values.states.includes(state.value)).map(state => state.label),
      systems: fields?.systems.filter(system => values.systems.includes(`${system.value}`)).map(system => system.label),
      period: {
        correctionRegisterDateRange,
        executionLimitDateRange,
        openingOfOccurrenceDateRange,
      },
      status: {
        ...values.status,
        occurrences: fields?.status?.occurrences
          .filter(occurrence => values.status.occurrences.includes(`${occurrence.value}`))
          .map(occurrence => occurrence.label),

        corrections: fields?.status?.corrections
          .filter(correction => values.status.corrections.includes(`${correction.value}`))
          .map(correction => correction.label),
      },
      people: {
        departments: fields?.people?.departments
          .filter(department => values.people.departments.includes(`${department.value}`))
          .map(department => department.label),
        providers: fields?.people?.providers
          .filter(provider => values.people.providers.includes(`${provider.value}`))
          .map(provider => provider.label),
        requesters: fields?.people?.requesters
          .filter(requester => values.people.requesters.includes(`${requester.value}`))
          .map(requester => requester.label),
        executors: fields?.people?.executors
          .filter(executor => values.people.executors.includes(`${executor.value}`))
          .map(executor => executor.label),
      },
      local: {
        areas: values?.local?.areas.map(area => Number(area)),
        areaGroups: fields?.local?.areaGroups
          .filter(areaGroup => values.local.areaGroups.includes(`${areaGroup.value}`))
          .map(areaGroup => areaGroup.label),
        areaSubgroups: fields?.local?.areaSubgroups
          .filter(areaSubgroup => values.local.areaSubgroups.includes(`${areaSubgroup.value}`))
          .map(areaSubgroup => areaSubgroup.label),
        equipmentTypes: fields?.local?.equipmentTypes
          .filter(equipmentType => values.local.equipmentTypes.includes(`${equipmentType.value}`))
          .map(equipmentType => equipmentType.label),
        equipments: values?.local?.equipments.map(equipment => Number(equipment)),
      },
      prioritization: {
        priorities: fields?.prioritization?.priorities
          .filter(priority => values.prioritization.priorities.includes(`${priority.value}`))
          .map(priority => priority.label),
        slas: fields?.prioritization?.slas
          .filter(sla => values.prioritization.slas.includes(`${sla.value}`))
          .map(sla => sla.label),
      },
      occurrences: {
        showOccurrencesPictures: values?.occurrences?.showOccurrencesPictures,
        sla: values?.occurrences?.sla,
        showMetrics: values?.metrics?.metricsForEachOccurrence,
      },
      corrections: {
        show: values?.corrections?.show,
        correctionTime: values?.corrections?.correctionTime,
        signatures: values?.corrections?.signatures,
        deadline: values?.corrections?.deadline,
        correctionPictures: values?.corrections?.correctionPictures,
        showCostAndMaterials: values?.corrections?.costsAndMaterials,
      },
    };
  };
  const validateRequiredFieldsExcel = () => {
    if (!getValues('showOnlyOpenOccurrences')) {
      if (
        !getValues('period.correctionRegister') &&
        !getValues('period.executionLimit') &&
        !getValues('period.openingOfOccurrence')
      ) {
        setToastList(prevState => [
          ...prevState,
          createToast({
            type: 'error',
            message: 'Por favor preencha um período.',
          }),
        ]);
        return true;
      }
      if (!getValues('status.occurrences')) {
        setToastList(prevState => [
          ...prevState,
          createToast({
            type: 'error',
            message: 'Por favor preencha um período.',
          }),
        ]);
        return true;
      }
    }
  };
  const validateRequiredFields = () => {
    if (!getValues('showOnlyOpenOccurrences')) {
      if (
        !getValues('period.correctionRegister') &&
        !getValues('period.executionLimit') &&
        !getValues('period.openingOfOccurrence')
      ) {
        setToastList(prevState => [
          ...prevState,
          createToast({
            type: 'error',
            message: 'Por favor preencha um período.',
          }),
        ]);
        return true;
      }
    }
    return false;
  };
  const generatePDF = async payload => {
    const userId = user?.id;
    const token = authStorage.token;

    const {
      companyId,
      unitGroups,
      unitSubgroups,
      states,
      systems,
      units,
      showOnlyOpenOccurrences,
      showAuditOccurrence,
      showOnlyDelayedOccurrences,
      period,
      status,
      people,
      local,
      prioritization,
      occurrences,
      corrections,
    } = payload;

    try {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'loading',
          message: 'Isso pode demorar um pouco. Você pode continuar suas tarefas enquanto isso.',
        }),
      ]);

      const response = await OccurrenceService.generatePDF({
        companyId,
        unitGroups,
        unitSubgroups,
        states,
        systems,
        units,
        showOnlyOpenOccurrences,
        showAuditOccurrence,
        showOnlyDelayedOccurrences,
        period,
        status,
        people,
        local,
        prioritization,
        occurrences,
        corrections,
        userId,
        token,
      });

      const { data } = response;
      if (!data) {
        return setToastList(() => [
          createToast({
            type: 'error',
            message: 'Não há dados para os filtros selecionados',
          }),
        ]);
      }
      setIsSendOcurrenceEmailSuccess(true);
      Sentry.captureMessage('Relatório Ocorrências PDF baixado');
    } catch (err) {
      if (err.response.status === 500) {
        setIsSendOcurrenceEmailError500(true);
      }
      if (err.response.status === 429) {
        setIsSendOcurrenceEmailError429(true);
      }
      if (err.response.status === 422) {
        setIsSendOcurrenceEmailError422(true);
      }
    }
  };

  const onSubmit = async values => {
    if (watch('reportType') === 'pdf') {
      const hasError = validateRequiredFields();
      if (hasError) {
        return;
      }
      const payload = formatPayload(values);
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'loading',
          message: 'Gerando arquivo...',
        }),
      ]);

      return generatePDF(payload);
    }
    if (watch('reportType') === 'excel') {
      const hasError = validateRequiredFieldsExcel();
      if (hasError) {
        return;
      }

      const payload = formatPayloadExcel(values);
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'loading',
          message: 'Gerando arquivo...',
        }),
      ]);

      return sendExcelByEmail(payload);
    }
  };
  const sendExcelByEmail = async payload => {
    let isGenerateReportSuccess = false;
    try {
      const {
        companyId,
        units,
        period,
        showOnlyOpenOccurrences,
        showOnlyDelayedOccurrences,
        showAuditOccurrence,
        systems,
        status,
        materials,
      } = payload;
      let excelObj;
      if (showOnlyOpenOccurrences) {
        excelObj = {
          token: authStorage.token,
          empresa: companyId,
          login: user.name,
          unidades: units,
          ocorrenciasEmAberto: showOnlyOpenOccurrences,
          ocorrenciasAuditoria: showAuditOccurrence,
          ocorrenciasAtrasadas: showOnlyDelayedOccurrences,
          sistemas: systems,
          statusOcorrencias: status.occurrences,
          materiais: materials,
        };
      } else {
        excelObj = {
          token: authStorage.token,
          empresa: companyId,
          login: user.name,
          dataInicial: period.openingOfOccurrenceDateRange.start,
          dataFinal: period.openingOfOccurrenceDateRange.end,
          unidades: units,
          ocorrenciasEmAberto: showOnlyOpenOccurrences,
          ocorrenciasAuditoria: showAuditOccurrence,
          ocorrenciasAtrasadas: showOnlyDelayedOccurrences,
          sistemas: systems,
          statusOcorrencias: status.occurrences,
          materiais: materials,
        };
      }
      const response = await OccurrenceService.sendExcelByEmail(excelObj);
      const { data } = response;
      if (!data) {
        setToastList(prevState => [
          ...prevState,
          createToast({
            type: 'error',
            message: 'Não há dados para os filtros selecionados',
          }),
        ]);
        return;
      }
      if (data === 'Ocorrências enviadas para fila de processamento') {
        isGenerateReportSuccess = true;
        setIsSendOcurrenceEmailSuccess(true);
      } else if (data === 'Relatorio Duplicado') {
        setDuplicatedReport(true);
      } else {
        throw new Error('sendExcelByEmail unexpected response');
      }
    } catch {
      createGenericErrorToast(setToastList);
    } finally {
      isGenerateReportSuccess && Sentry.captureMessage('Relatório Excel enviado');
    }
  };
  const openModalSavedFilters = () =>
    setModalOptions({
      title: 'Filtros salvos',
      open: true,
    });

  const formatDateRangeToString = dateRange => {
    if (!dateRange) {
      return '';
    }

    const { startDate, endDate } = dateRange;

    if (startDate && endDate) {
      return startDate + '/' + endDate;
    }

    return '';
  };

  const applySavedFilter = data => {
    const {
      unitGroups = [],
      unitSubgroups = [],
      units = [],
      states = [],
      systems = [],
      showOnlyOpenOccurrences = false,
      showAuditOccurrence = false,
      showOnlyDelayedOccurrences = false,
      period,
      status,
      people,
      local,
      prioritization,
      occurrences,
      corrections,
      metrics,
      reportType,
    } = data;

    reset({
      unitGroups,
      unitSubgroups,
      units,
      states,
      systems,
      showOnlyOpenOccurrences,
      showAuditOccurrence,
      showOnlyDelayedOccurrences,
      period: {
        correctionRegister: formatDateRangeToString(period.correctionRegisterDateRange),
        executionLimit: formatDateRangeToString(period.executionLimitDateRange),
        openingOfOccurrence: formatDateRangeToString(period.openingOfOccurrenceDateRange),
      },
      status,
      people,
      local,
      prioritization,
      occurrences,
      corrections,
      metrics,
      reportType,
    });
  };

  const onSelectFilter = async selectedFilter => {
    const { id, name } = selectedFilter;

    try {
      setGlobalComponentsContext(prevState => ({
        ...prevState,
        isLoading: true,
      }));

      const response = await SavedFiltersService.getSavedFilters({
        companyId: headerFilters?.companies?.id,
        filterSavedId: id,
      });

      applySavedFilter({ ...response.data, filterName: name });

      setCurrentSavedFilter({ filterName: name, filterId: id });

      const fieldsData = await getSecondColumnFields();

      await setFields(prevState => ({
        ...prevState,
        ...fieldsData,
      }));
    } catch (err) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Ocorreu um erro ao buscar o filtro selecionado.',
        }),
      ]);
    } finally {
      setModalOptions({
        open: false,
      });
      setGlobalComponentsContext(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const saveFilters = async () => {
    try {
      const formValues = getValues();

      const payload = formatPayload(formValues);

      const {
        filterName,
        unitGroups,
        unitSubgroups,
        units,
        states,
        systems,
        showAuditOccurrence,
        showOnlyDelayedOccurrences,
        showOnlyOpenOccurrences,
        period,
        status,
        people,
        local,
        prioritization,
        occurrences,
        corrections,
        metrics,
        reportType,
      } = payload;

      const companyId = headerFilters?.companies?.id;
      const userId = user?.id;

      await SavedFiltersService.createSavedFilter({
        companyId,
        userId,
        filterName,
        reportName,
        fields: {
          unitGroups,
          unitSubgroups,
          units,
          states,
          systems,
          showAuditOccurrence,
          showOnlyDelayedOccurrences,
          showOnlyOpenOccurrences,
          period,
          status,
          people,
          local,
          prioritization,
          occurrences,
          corrections,
          metrics,
          reportType,
        },
      });

      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'success',
          message: 'Filtros salvos com sucesso',
        }),
      ]);
    } catch (err) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Ocorreu um erro ao salvar os filtros',
        }),
      ]);
    }
  };

  const deleteSavedFilter = async selectedFilter => {
    const { id } = selectedFilter;

    const companyId = headerFilters?.companies?.id;

    try {
      await SavedFiltersService.removeSavedFilter({
        companyId,
        filterSavedId: id,
      });
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'success',
          message: 'O filtro selecionado foi deletado com sucesso',
        }),
      ]);
    } catch (e) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Ocorreu um erro ao deletar o filtro selecionado. Tente novamente.',
        }),
      ]);
    } finally {
      setModalOptions({
        open: false,
      });
    }
  };

  const getModalOptionsData = async () => {
    const companyId = headerFilters?.companies?.id;
    const userId = user?.id;

    try {
      const response = await SavedFiltersService.findSavedFiltersByReportName({
        reportName,
        companyId,
        userId,
      });
      return response.data;
    } catch (e) {
      setToastList(prevState => [
        ...prevState,
        createToast({
          type: 'error',
          message: 'Ocorreu um erro ao buscar os filtros salvos. Tente novamente..',
        }),
      ]);
    }
  };

  const handleDisabledFieldRules = key => {
    switch (key) {
      case 'unitGroups':
        return watch('unitSubgroups')?.length > 0 || watch('states')?.length > 0 || watch('units')?.length > 0;
      case 'unitSubgroups':
        return watch('states')?.length > 0 || watch('units')?.length > 0;
      case 'states':
        return watch('units')?.length > 0;

      // People
      // case 'providers':
      //   return (
      //     watch('people.executors')?.length > 0 ||
      //     watch('local.areaGroups')?.length > 0 ||
      //     watch('local.areaSubgroups')?.length > 0 ||
      //     watch('local.areas')?.length > 0 ||
      //     watch('local.equipments')?.length > 0
      //   );

      // Local
      case 'areaGroups':
        return (
          watch('local.areaSubgroups')?.length > 0 ||
          watch('local.areas')?.length > 0 ||
          watch('local.equipments')?.length > 0
        );
      case 'areaSubgroups':
        return watch('local.areas')?.length > 0 || watch('local.equipments')?.length > 0;
      case 'areas':
        return watch('local.equipments')?.length > 0;
      case 'equipmentTypes':
        return watch('local.equipments')?.length > 0;
      default:
        return false;
    }
  };

  const goback = () => history.push('/relatorios');

  const cleanFields = () => {
    setValue('unitGroups', []);
    setValue('unitSubgroups', []);
    setValue('states', []);
    setValue('systems', []);
    setValue('units', []);
    setValue('showOnlyOpenOccurrences', false);
    setValue('showAuditOccurrence', false);
    setValue('showOnlyDelayedOccurrences', false);
    setValue('reportType', 'pdf');

    // Period
    setValue('period.correctionRegister', '');
    setValue('period.executionLimit', '');
    setValue('period.openingOfOccurrence', '');

    // Status
    setValue('status.occurrences', []);
    setValue('status.corrections', []);
    // setValue('status.occurrenceNumbers', []);

    // People
    setValue('people.departments', []);
    setValue('people.providers', []);
    setValue('people.executors', []);
    setValue('people.requesters', []);

    // Local
    setValue('local.areaGroups', []);
    setValue('local.areaSubgroups', []);
    setValue('local.areas', []);
    setValue('local.equipmentTypes', []);
    setValue('local.equipments', []);

    // Prioritization
    setValue('prioritization.priorities', []);
    setValue('prioritization.slas', []);

    //Occurrences
    setValue('occurrences.showOccurrencesPictures', true);
    setValue('occurrences.sla', true);

    // Corrections
    setValue('corrections.show', 'all');
    setValue('corrections.correctionTime', true);
    setValue('corrections.signatures', true);
    setValue('corrections.deadline', true);
    setValue('corrections.costsAndMaterials', true);
    setValue('corrections.correctionPictures', true);

    // Metrics
    setValue('metrics.metricsForEachOccurrence', true);
    setValue('metrics.summaryOfAllOccurrences', true);

    setCurrentSavedFilter('');

    fetchData('FIRST_COLUMN');
  };

  const getFirstColumnFields = async () => {
    const companyId = headerFilters?.companies?.id;
    const unitGroupIds = getValues('unitGroups');
    const unitSubgroupIds = getValues('unitSubgroups');
    const stateIds = getValues('states');
    return Promise.all([
      FiltersService.getUnitGroups({
        companyId,
      }),
      FiltersService.getUnitSubgroups({
        companyId,
        unitGroupIds,
      }),
      FiltersService.getStates({
        companyId,
        unitGroupIds,
        unitSubgroupIds,
      }),
      FiltersService.getSystems({ companyId }),
      FiltersService.getUnits({
        companyId,
        unitGroupIds,
        unitSubgroupIds,
        stateIds,
      }),
      ReportService.getGeneratedReports({
        usuarioId: user.id,
        tipos: [2, 5],
        token: authStorage.token,
      }),
    ]).then(values => {
      return {
        unitGroups: values[0]?.data.map(({ nome, grupoUnidadeId }) => ({
          label: nome,
          value: grupoUnidadeId,
        })),
        unitSubgroups: values[1]?.data.map(({ nome, subGrupoUnidadeId }) => ({
          label: nome,
          value: subGrupoUnidadeId,
        })),
        states: values[2]?.data.map(({ name, id }) => ({
          label: name,
          value: id,
        })),
        systems: values[3]?.data.map(({ nome, sistemaEmpresa }) => ({
          label: nome,
          value: sistemaEmpresa,
        })),
        units: values[4]?.data.map(({ nome, site }) => ({
          label: nome,
          value: site,
        })),
        generatedReports: values[5]?.data.sort((a, b) => {
          return a.emissao > b.emissao ? -1 : a.emissao < b.emissao ? 1 : 0;
        }),
      };
    });
  };

  const getStatusAccordionData = async () => {
    const companyId = headerFilters?.companies?.id;
    const occurrenceStatusIds = getValues('status.occurrences');

    return Promise.all([
      FiltersService.getOccurrenceStatus({ companyId }),
      // FiltersService.getOcurrenceNumbers({ companyId }),
      FiltersService.getCorrectionStatus({
        companyId,
        occurrenceStatusIds,
      }),
    ]).then(values => ({
      status: {
        occurrences: values[0].data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        // occurrenceNumbers: values[1].data.map((occurrenceNumber) => ({
        //   label: occurrenceNumber,
        //   value: occurrenceNumber
        // })),
        corrections: values[1].data.map(({ correctiveTypeActionCompany, name }) => ({
          label: name,
          value: correctiveTypeActionCompany,
        })),
      },
    }));
  };

  const getLocalAccordionData = async () => {
    const companyId = headerFilters?.companies?.id;
    const checkUnits = getValues('units');
    const unitIds = checkUnits.length === fields?.units.length ? [] : checkUnits;
    const providerIds = getValues('people.providers');
    const areaGroupIds = getValues('local.areaGroups');
    const areaSubgroupIds = getValues('local.areaSubgroups');
    const areaIds = getValues('local.areas');
    const equipmentTypeIds = getValues('local.equipmentTypes');

    return Promise.all([
      FiltersService.getAreaGroups({
        companyId,
        unitIds,
        providerIds,
        areaIds,
        areaSubgroupIds,
      }),
      FiltersService.getAreaSubgroups({
        companyId,
        unitIds,
        providerIds,
        areaGroupIds,
        areaIds,
      }),
      FiltersService.getAreas({
        companyId,
        ids: areaIds,
        unitIds,
        providerIds,
        areaGroupIds,
        areaSubgroupIds,
      }),
      FiltersService.getEquipmentTypes({ companyId }),
      FiltersService.getEquipments({
        companyId,
        providerIds,
        areaGroupIds,
        areaSubgroupIds,
        areaIds,
        unitIds,
        equipmentTypeIds,
      }),
    ]).then(values => ({
      local: {
        areaGroups: values[0].data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        areaSubgroups: values[1].data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        areas: values[2].data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        equipmentTypes: values[3].data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        equipments: values[4].data.map(({ id, tag, name }) => ({
          label: `${tag} - ${name}`,
          value: id,
        })),
      },
    }));
  };

  const getPrioritizationAccordionData = async () => {
    const companyId = headerFilters?.companies?.id;
    const checkUnits = getValues('units');
    const unitIds = checkUnits.length === fields?.units.length ? [] : checkUnits;
    return Promise.all([
      FiltersService.getPriorities({ companyId, unitIds }),
      FiltersService.getSlas({ companyId, unitIds: checkUnits }),
    ]).then(values => ({
      prioritization: {
        priorities: values[0].data.map(({ id, description }) => ({
          label: description,
          value: id,
        })),
        slas: values[1].data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      },
    }));
  };

  const getPeopleAccordionData = async () => {
    const companyId = headerFilters?.companies?.id;
    const checkUnits = getValues('units');
    const unitIds = checkUnits.length === fields?.units.length ? [] : checkUnits;
    const providerIds = getValues('people.providers');
    return Promise.all([
      FiltersService.getDepartments({ companyId, unitIds: checkUnits }),
      FiltersService.getProviders({ companyId, unitIds: checkUnits }),
      FiltersService.getUsers({
        companyId,
        unitIds,
        providerIds,
      }),
    ]).then(values => ({
      people: {
        departments: values[0].data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        providers: values[1].data.map(({ id, fantasyName }) => ({
          label: fantasyName,
          value: id,
        })),
        executors: values[2].data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        requesters: values[2].data.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      },
    }));
  };

  const getSecondColumnFields = async () => {
    return Promise.all([
      getStatusAccordionData(),
      getLocalAccordionData(),
      getPrioritizationAccordionData(),
      getPeopleAccordionData(),
    ]).then(values => ({
      ...values[0],
      ...values[1],
      ...values[2],
      ...values[3],
    }));
  };

  const populateFieldsStrategy = async strategy => {
    if (strategy === 'MOUNT') {
      return getFirstColumnFields();
    }

    if (strategy === 'FIRST_COLUMN') {
      return getFirstColumnFields();
    }

    if (strategy === 'SECOND_COLUMN') {
      return getSecondColumnFields();
    }

    if (strategy === 'ALL') {
      return Promise.all([getFirstColumnFields(), getSecondColumnFields()]).then(values => ({
        ...values[0],
        ...values[1],
      }));
    }
  };

  const populateFields = async strategy => {
    try {
      const fieldsData = await populateFieldsStrategy(strategy);
      setFields(prevState => ({
        ...prevState,
        ...fieldsData,
      }));
    } catch (e) {
      createGenericErrorToast(setToastList);
    }
  };

  const fetchData = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      const login = user?.name;
      const { token } = authStorage;
      const { data } = await OccurrenceService.validateLogin(login, token);
      if (data === 'Email Valido') {
        setIsEmailVerified(true);
      }
      const activeUnits = getValues('units');
      const strategy = activeUnits && activeUnits.length > 0 ? 'ALL' : 'MOUNT';
      await populateFields(strategy);
    } catch (err) {
      if (err.message.includes('Network Error')) {
        history.push('/conection-error');
      }
      createGenericErrorToast(setToastList);
    } finally {
      setGlobalComponentsContext(prevState => ({
        ...prevState,
        isLoading: false,
      }));
    }
    if (!headerFilters?.companies?.id) {
      createErrorToast(setToastList, 'Selecione uma empresa para continuar');
      return;
    }
  };
  const [unitId, setUnitId] = useState(headerFilters?.units?.id || '');
  useEffect(() => {
    fetchData().then(() => {
      const activeFormUnitsValue = getValues('units');
      setUnitId(getValues('unit'));
      if (headerFilters.units && headerFilters.units.id) {
        // todo hois remove duplicate array values spread to function
        const newUnits = [...new Set([...activeFormUnitsValue, headerFilters.units.id])];

        setValue('units', newUnits);
      }
    });

    // eslint-disable-next-line
  }, [headerFilters.units, errors, unitId]);

  useEffect(() => {
    if (getValues('showOnlyOpenOccurrences')) {
      const notAnalyzed = 1;
      const analyzed = 3;

      const valuesToSet = [notAnalyzed, analyzed];

      setValue(
        'status.occurrences',
        fields?.status?.occurrences.filter(item => valuesToSet.includes(item.value)).map(item => `${item.value}`),
      );
    } else {
      setValue('status.occurrences', []);
    }
    // eslint-disable-next-line
  }, [watch('showOnlyOpenOccurrences')]);

  const tooltipOfFieldDisabled = 'Limpe os campos abaixo para habilitar';
  const isPageDisabled = !isEmailVerified;
  const columns = useMemo(
    () => [
      {
        Header: 'Arquivo',
        accessor: 'arquivo',
      },
      {
        Header: 'Data/hora de emissão',
        accessor: 'dataHoraEmissao',
      },
      {
        Header: 'Unidade(s)',
        accessor: 'unidade',
      },
      {
        Header: 'Período',
        accessor: 'periodo',
      },
    ],
    [],
  );

  const downloadReport = async (generatedReport, e) => {
    e.preventDefault();
    const result = await ReportService.getUrlToDownload({
      token,
      nomeArquivo: generatedReport.nomeArquivo,
      tipo: generatedReport.tipo,
    });
    window.open(result.data);
    return false;
  };
  const getDate = emissao => {
    const dateString = new Date(emissao);
    const date = dateString.toLocaleDateString();
    const time = dateString.toLocaleTimeString();
    return `${date} • ${time} `;
  };
  const handleRedoReport = async (id, e) => {
    e.preventDefault();
    await ReportService.redoReport({ id, token });
    updateGeneratedReports(e);
  };
  const dataTable = fields?.generatedReports?.map(generatedReport => ({
    arquivo:
      generatedReport.status === 2 ? (
        <div className="download-report">
          <DownloadIcon />
          <DownloadButton onClick={e => downloadReport(generatedReport, e)}>
            <b>Baixar Relatório{generatedReport.tipo === 2 ? ' (EXCEL)' : ' (PDF)'}</b>
          </DownloadButton>
        </div>
      ) : generatedReport.status === 4 ? (
        <div className="download-report">
          <StyledWarningIcon />
          <StatusWarningInfo>
            ERRO: TENTE NOVAMENTE
            <RedoReportButton onClick={e => handleRedoReport(generatedReport.id, e)}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" stroke="#E5E5E5" />
                <path
                  d="M21.7144 10H20.5716C20.4287 10 20.2859 10.1429 20.2859 10.2857C20.2859 10.3095 20.2859 10.3095 20.2859 10.3095L20.3811 12.2857C19.3097 11 17.6906 10.1905 15.9049 10.1905C12.643 10.1905 9.97635 12.8571 10.0002 16.119C10.0002 19.381 12.643 22 15.9049 22C17.4287 22 18.8097 21.4286 19.8573 20.5C19.9049 20.4286 19.9525 20.3571 19.9525 20.2857C19.9525 20.1905 19.9049 20.119 19.8573 20.0714L19.0478 19.2619C19.0002 19.2143 18.9287 19.1905 18.8573 19.1905C18.7859 19.1905 18.7144 19.2143 18.6668 19.2619C17.9287 19.9048 16.9525 20.2857 15.9049 20.2857C13.5716 20.2857 11.7144 18.4286 11.7144 16.0952C11.7144 13.7857 13.5716 11.9048 15.9049 11.9048C17.3335 11.9048 18.6192 12.6429 19.3811 13.7619L16.9525 13.6429C16.9525 13.6429 16.9525 13.6429 16.9287 13.6429C16.7859 13.6429 16.643 13.7857 16.643 13.9286H16.6668V15.0714C16.6668 15.2143 16.7859 15.3571 16.9525 15.3571H21.7144C21.8573 15.3571 22.0002 15.2143 22.0002 15.0714V10.2857C22.0002 10.1429 21.8573 10 21.7144 10Z"
                  fill="#1E1E1E"
                />
              </svg>
            </RedoReportButton>
          </StatusWarningInfo>
        </div>
      ) : generatedReport.status === 5 ? (
        <div className="download-report">
          <StyledWarningIcon />
          <StatusWarningInfo>NÃO GERADO (SEM DADOS)</StatusWarningInfo>
          <GenericTooltip
            left={'-217%'}
            bottom={'25px'}
            afterLeft={'49%'}
            afterTop={'86%'}
            text="Não há dados para os filtros selecionados. Tente gerar o relatório novamente utilizando outros filtros."
          />
        </div>
      ) : generatedReport.status === 6 ? (
        <div className="download-report">
          <StyledWarningIcon />
          <StatusWarningInfo> agendado para mais tarde</StatusWarningInfo>
          <GenericTooltip
            bottom={'25px'}
            left={'-215%'}
            afterLeft={'50%'}
            afterTop={'92%'}
            top="92%"
            text="Este relatório é muito grande e será gerado em um horário de menor demanda para garantir o melhor desempenho do sistema. Você receberá um aviso por e-mail assim que ele estiver disponível para download."
          />
        </div>
      ) : (
        <div className="download-report">
          <Spinner />
          <StatusInfo>GERANDO RELATÓRIO</StatusInfo>
        </div>
      ),
    dataHoraEmissao: getDate(generatedReport.emissao),
    unidade: generatedReport.unidades,
    periodo: generatedReport.periodo,
  }));
  const sendEmail = () => {
    setIsSendOcurrenceEmailSuccess(false);
    setActive(true);
  };
  const closeModal = () => {
    setIsSendOcurrenceEmailSuccess(false);
    setActive(true);
  };
  const updateGeneratedReports = async e => {
    e.preventDefault();
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    const fieldsData = await ReportService.getGeneratedReports({
      usuarioId: user.id,
      tipos: [2, 5],
      token: authStorage.token,
    }).then(values => ({
      generatedReports: values?.data.sort((a, b) => {
        return a.emissao > b.emissao ? -1 : a.emissao < b.emissao ? 1 : 0;
      }),
    }));
    setFields(prevState => ({
      ...prevState,
      ...fieldsData,
    }));
    setActive(true);
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  };
  const updateGeneratedReports60S = async () => {
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    const fieldsData = await ReportService.getGeneratedReports({
      usuarioId: user.id,
      tipos: [2, 5],
      token: authStorage.token,
    }).then(values => ({
      generatedReports: values?.data.sort((a, b) => {
        return a.emissao > b.emissao ? -1 : a.emissao < b.emissao ? 1 : 0;
      }),
    }));
    setFields(prevState => ({
      ...prevState,
      ...fieldsData,
    }));
    setActive(true);
    setGlobalComponentsContext(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  };
  const checkBoxOptions = [
    {
      label: 'Materiais',
    },
  ];
  const tabs = [
    {
      id: 0,
      title: 'Gerar relatório',
      content: (
        <>
          <ReportContainerColumn justifyContent="flex-end" gridColumnStart={2}>
            <ReportContainerFields>
              <GridColumn size={1} columnGap="1rem" rowGap="1rem">
                <FormGroup
                  label="Grupo de unidades"
                  disabled={fields?.unitGroups?.length <= 0 || handleDisabledFieldRules('unitGroups')}
                  showTooltip={handleDisabledFieldRules('unitGroups')}
                  tooltipMessage={tooltipOfFieldDisabled}
                >
                  <Controller
                    render={props => (
                      <MultiSelect
                        {...props}
                        size="small"
                        placeholder="Seleção opcional"
                        options={fields?.unitGroups}
                        disabled={handleDisabledFieldRules('unitGroups')}
                        onChange={value => {
                          props.onChange(value);
                          populateFields('FIRST_COLUMN');
                        }}
                      />
                    )}
                    name="unitGroups"
                    control={control}
                  />
                </FormGroup>

                <FormGroup
                  label="Subgrupo de unidades"
                  disabled={fields?.unitSubgroups?.length <= 0 || handleDisabledFieldRules('unitSubgroups')}
                  showTooltip={handleDisabledFieldRules('unitSubgroups')}
                  tooltipMessage={tooltipOfFieldDisabled}
                >
                  <Controller
                    render={props => (
                      <MultiSelect
                        {...props}
                        size="small"
                        placeholder="Seleção opcional"
                        options={fields?.unitSubgroups}
                        disabled={handleDisabledFieldRules('unitSubgroups')}
                        onChange={value => {
                          props.onChange(value);
                          populateFields('FIRST_COLUMN');
                        }}
                      />
                    )}
                    name="unitSubgroups"
                    control={control}
                    defaultValue={[]}
                  />
                </FormGroup>

                <FormGroup
                  label="Estado"
                  disabled={fields?.states?.length <= 0 || handleDisabledFieldRules('states')}
                  showTooltip={handleDisabledFieldRules('states')}
                  tooltipMessage={tooltipOfFieldDisabled}
                >
                  <Controller
                    render={props => (
                      <MultiSelect
                        {...props}
                        size="small"
                        placeholder="Seleção opcional"
                        options={fields?.states}
                        disabled={handleDisabledFieldRules('states')}
                        onChange={value => {
                          props.onChange(value);
                          populateFields('FIRST_COLUMN');
                        }}
                      />
                    )}
                    name="states"
                    control={control}
                    defaultValue={[]}
                  />
                </FormGroup>
                <FormGroup label="Sistema" disabled={fields?.systems?.length <= 0}>
                  <Controller
                    render={props => (
                      <MultiSelect
                        {...props}
                        size="small"
                        placeholder="Seleção opcional"
                        options={fields?.systems}
                        onChange={value => {
                          props.onChange(value);
                          populateFields('FIRST_COLUMN');
                        }}
                      />
                    )}
                    name="systems"
                    control={control}
                    defaultValue={[]}
                  />
                </FormGroup>
                <FormGroup
                  label="Unidade de manutenção"
                  disabled={fields?.units?.length <= 0 || handleDisabledFieldRules('units')}
                  isRequired
                >
                  <Controller
                    render={props => (
                      <MultiSelect
                        {...props}
                        size="small"
                        placeholder="Seleção obrigatória"
                        options={fields?.units}
                        onChange={value => {
                          props.onChange(value);

                          populateFields('ALL');
                        }}
                      />
                    )}
                    name="units"
                    control={control}
                    defaultValue={headerFilters?.units?.id || []}
                  />
                </FormGroup>
                <div />

                <Switch
                  label="Apresentar somente ocorrências em aberto"
                  name="showOnlyOpenOccurrences"
                  ref={register()}
                />
                <Switch label="Apresentar ocorrências de auditoria" name="showAuditOccurrence" ref={register()} />
                <Switch
                  label="Apresentar somente ocorrências atrasadas"
                  name="showOnlyDelayedOccurrences"
                  ref={register()}
                />
              </GridColumn>

              <FieldRow marginBottom="2rem" />

              <FormGroup label="Formato do relatório" isRequired>
                <StyledFormRadio>
                  <Radio text="PDF (texto e fotos)" id="reportPdf" name="reportType" value="pdf" ref={register()} />
                  <Radio
                    text="Excel (apenas texto)"
                    type="radio"
                    id="reportExcel"
                    disabled={!isEmailVerified}
                    name="reportType"
                    value="excel"
                    ref={register()}
                  />
                </StyledFormRadio>
              </FormGroup>

              <FieldRow marginBottom="2rem" />

              <Conditional when={watch('units')?.length > 0}>
                <Preview
                  reportName={reportName}
                  reportType={watch('reportType')}
                  params={{
                    occurrences: watch('occurrences'),
                    corrections: watch('corrections'),
                    metrics: watch('metrics'),
                  }}
                />
              </Conditional>
            </ReportContainerFields>
          </ReportContainerColumn>
          <LineSeparator show={watch('units')?.length > 0} />
          <Conditional when={!watch('units') || watch('units')?.length <= 0}>
            <ReportContainerFillFields />
          </Conditional>
          <Conditional when={watch('units')?.length > 0 && !isLoading}>
            <ReportContainerColumn>
              <ReportContainerFields>
                <Conditional when={!watch('showOnlyOpenOccurrences')}></Conditional>
                <Conditional when={watch('reportType') === 'pdf'}>
                  <Accordion titulo="Período" isRequired>
                    <GridColumn size={1} columnGap="1rem" rowGap="1rem">
                      <StyledRadio>
                        <StyleDivRadio>
                          <Radio
                            text="Abertura da ocorrência"
                            id="report-open"
                            name="reportPeriod"
                            value="reportOpen"
                            ref={register()}
                          />
                          <div>
                            <InformativeTooltip
                              className="OpenTooltip"
                              message="Filtrará as ocorrências que foram abertas nesse período."
                            />
                          </div>
                        </StyleDivRadio>
                        <StyleDivRadio>
                          <Radio
                            text="Cadastro de correção"
                            id="report-start"
                            name="reportPeriod"
                            value="reportStart"
                            ref={register()}
                          />
                          <InformativeTooltip message="Filtrará as ocorrências que tiverem correções cadastradas nesse período." />
                        </StyleDivRadio>
                        <StyleDivRadio>
                          <Radio
                            text="Limite de execução"
                            id="report-limit"
                            name="reportPeriod"
                            value="reportLimit"
                            ref={register()}
                          />
                          <InformativeTooltip message="Filtrará as ocorrências com limite de execução compreendido nesse período." />
                        </StyleDivRadio>
                      </StyledRadio>
                      {watch('reportPeriod') === 'reportOpen' && (
                        <FormGroup label="Abertura da ocorrência">
                          <Controller
                            render={props => (
                              <DateRangePicker
                                {...props}
                                data-cy="opening-of-occurrence-date-picker"
                                size="small"
                                placeholder="Seleção obrigatória"
                              />
                            )}
                            name="period.openingOfOccurrence"
                            control={control}
                            defaultValue={currentSavedFilter?.period?.openingOfOccurrence || ''}
                          />
                        </FormGroup>
                      )}
                      {watch('reportPeriod') === 'reportStart' && (
                        <FormGroup label="Cadastro de correção">
                          <Controller
                            render={props => (
                              <DateRangePicker {...props} size="small" placeholder="Seleção obrigatória" />
                            )}
                            name="period.correctionRegister"
                            control={control}
                            defaultValue={currentSavedFilter?.period?.correctionRegister || ''}
                          />
                        </FormGroup>
                      )}
                      {watch('reportPeriod') === 'reportLimit' && (
                        <FormGroup label="Limite de execução">
                          <Controller
                            render={props => (
                              <DateRangePicker {...props} size="small" placeholder="Seleção obrigatória" />
                            )}
                            name="period.executionLimit"
                            control={control}
                            defaultValue={currentSavedFilter?.period?.executionLimit || ''}
                          />
                        </FormGroup>
                      )}
                    </GridColumn>
                  </Accordion>
                  <FieldRow marginBottom="1rem" />
                  <Accordion titulo="Status" isRequired>
                    <GridColumn size={2} columnGap="1rem">
                      <FormGroup
                        label="Status da ocorrência"
                        disabled={fields?.status?.occurrences?.length <= 0 || watch('showOnlyOpenOccurrences')}
                        isRequired
                      >
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              placeholder="Seleção obrigatória"
                              options={fields?.status?.occurrences}
                              disabled={watch('showOnlyOpenOccurrences')}
                              onChange={value => {
                                props.onChange(value);
                                populateFields('SECOND_COLUMN');
                              }}
                            />
                          )}
                          name="status.occurrences"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup label="Status da correção" disabled={fields?.status?.corrections?.length <= 0}>
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.status?.corrections}
                            />
                          )}
                          name="status.corrections"
                          control={control}
                          defaultValue={[]}
                        />
                        {/* <StyledOnlyCurrentCorrectionStatus>
                            <Checkbox
                              text="Apenas status de correção atual"
                              name="status.showOnlyCurrentCorrectionStatus"
                              ref={register()}
                            />
                            <InformativeTooltip />
                            </StyledOnlyCurrentCorrectionStatus> */}
                      </FormGroup>
                      {/* <FormGroup
                          label="Ocorrência"
                          disabled={
                            fields?.status?.occurrenceNumbers
                              ?.length <= 0
                          }
                        >
                          <Controller
                            render={(props) => (
                              <ModalMultiSelect
                                {...props}
                                title="Ocorrência"
                                size="small"
                                placeholder="Seleção opcional"
                                options={
                                  fields?.status?.occurrenceNumbers
                                }
                                onChange={(value) => {
                                  props.onChange(value);
                                }}
                              />
                            )}
                            name="status.occurrenceNumbers"
                            control={control}
                            defaultValue={[]}
                          />
                              </FormGroup>*/}
                    </GridColumn>
                  </Accordion>
                  <FieldRow marginBottom="1rem" />
                  <Accordion titulo="Pessoas">
                    <GridColumn size={2} columnGap="1rem" rowGap="1rem">
                      <FormGroup
                        label="Fornecedor"
                        disabled={fields?.people?.providers?.length <= 0 || handleDisabledFieldRules('providers')}
                        showTooltip={handleDisabledFieldRules('providers')}
                        tooltipMessage={tooltipOfFieldDisabled}
                      >
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.people?.providers}
                              disabled={handleDisabledFieldRules('providers')}
                              onChange={value => {
                                props.onChange(value);
                                populateFields('SECOND_COLUMN');
                              }}
                            />
                          )}
                          name="people.providers"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup label="Executor" disabled={fields?.people?.executors?.length <= 0}>
                        <Controller
                          render={props => (
                            <MultiSelect
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.people?.executors}
                              {...props}
                            />
                          )}
                          name="people.executors"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup label="Solicitante" disabled={fields?.people?.requesters?.length <= 0}>
                        <Controller
                          render={props => (
                            <MultiSelect
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.people?.requesters}
                              {...props}
                            />
                          )}
                          name="people.requesters"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                    </GridColumn>
                  </Accordion>
                  <FieldRow marginBottom="1rem" />
                  <Accordion titulo="Local / Onde Ocorreu">
                    <GridColumn size={1} columnGap="1rem" rowGap="1rem">
                      <FormGroup
                        label="Grupo de áreas"
                        disabled={fields?.local?.areaGroups?.length <= 0 || handleDisabledFieldRules('areaGroups')}
                        showTooltip={handleDisabledFieldRules('areaGroups')}
                        tooltipMessage={tooltipOfFieldDisabled}
                      >
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.local?.areaGroups}
                              disabled={handleDisabledFieldRules('areaGroups')}
                              onChange={value => {
                                props.onChange(value);
                                populateFields('SECOND_COLUMN');
                              }}
                            />
                          )}
                          name="local.areaGroups"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup
                        label="Subgrupo de áreas"
                        disabled={
                          fields?.local?.areaSubgroups?.length <= 0 || handleDisabledFieldRules('areaSubgroups')
                        }
                        showTooltip={handleDisabledFieldRules('areaSubgroups')}
                        tooltipMessage={tooltipOfFieldDisabled}
                      >
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.local?.areaSubgroups}
                              disabled={handleDisabledFieldRules('areaSubgroups')}
                              onChange={value => {
                                props.onChange(value);
                                populateFields('SECOND_COLUMN');
                              }}
                            />
                          )}
                          name="local.areaSubgroups"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup
                        label="Área"
                        disabled={fields?.local?.areas?.length <= 0 || handleDisabledFieldRules('areas')}
                        showTooltip={handleDisabledFieldRules('areas')}
                        tooltipMessage={tooltipOfFieldDisabled}
                      >
                        <Controller
                          render={props => (
                            <ModalMultiSelect
                              {...props}
                              title="Área"
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.local?.areas}
                              disabled={handleDisabledFieldRules('areas')}
                              onChange={value => {
                                props.onChange(value);
                                populateFields('SECOND_COLUMN');
                              }}
                            />
                          )}
                          name="local.areas"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>

                      <FormGroup
                        label="Tipo de equipamento"
                        disabled={
                          fields?.local?.equipmentTypes?.length <= 0 || handleDisabledFieldRules('equipmentTypes')
                        }
                        showTooltip={handleDisabledFieldRules('equipmentTypes')}
                        tooltipMessage={tooltipOfFieldDisabled}
                      >
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.local?.equipmentTypes}
                              disabled={handleDisabledFieldRules('equipmentTypes')}
                              onChange={value => {
                                props.onChange(value);
                                populateFields('SECOND_COLUMN');
                              }}
                            />
                          )}
                          name="local.equipmentTypes"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>

                      <FormGroup label="Equipamento" disabled={fields?.local?.equipments?.length <= 0}>
                        <Controller
                          render={props => (
                            <ModalMultiSelect
                              {...props}
                              title="Equipamento"
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.local?.equipments}
                            />
                          )}
                          name="local.equipments"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup label="Setor" disabled={fields?.people?.departments?.length <= 0}>
                        <Controller
                          render={props => (
                            <MultiSelect
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.people?.departments}
                              {...props}
                            />
                          )}
                          name="people.departments"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                    </GridColumn>
                  </Accordion>
                  <FieldRow marginBottom="1rem" />
                  <Accordion titulo="Priorização">
                    <GridColumn size={2} columnGap="1rem" rowGap="1rem">
                      <FormGroup label="Prioridade" disabled={fields?.prioritization?.priorities?.length <= 0}>
                        <Controller
                          render={props => (
                            <MultiSelect
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.prioritization?.priorities}
                              {...props}
                            />
                          )}
                          name="prioritization.priorities"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                      <FormGroup label="SLA" disabled={fields?.prioritization?.slas?.length <= 0}>
                        <Controller
                          render={props => (
                            <MultiSelect
                              size="small"
                              placeholder="Seleção opcional"
                              options={fields?.prioritization?.slas}
                              {...props}
                            />
                          )}
                          name="prioritization.slas"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                    </GridColumn>
                  </Accordion>
                  <FieldRow marginBottom="1rem" />
                  <Accordion titulo="Ocorrências">
                    <GridColumn size={2} columnGap="1rem">
                      <Switch
                        label="Fotos das ocorrências"
                        name="occurrences.showOccurrencesPictures"
                        ref={register()}
                      />
                      <Switch label="SLA" name="occurrences.sla" ref={register()} />
                    </GridColumn>
                  </Accordion>
                  <FieldRow marginBottom="1rem" />

                  <Accordion titulo="Correções">
                    <StyledFormRadio>
                      <label>Exibir: </label>
                      <Radio
                        text="Todas as correções"
                        id="showAllCorrection"
                        name="corrections.show"
                        value="all"
                        ref={register()}
                      />
                      <Radio
                        text="Apenas a última correção"
                        id="showLastCorrection"
                        name="corrections.show"
                        value="last"
                        ref={register()}
                      />
                    </StyledFormRadio>
                    <FieldRow marginBottom="1rem" />

                    <GridColumn size={2} columnGap="1rem" rowGap="1rem">
                      <Switch label="Tempo de correção" name="corrections.correctionTime" ref={register()} />
                      <Switch label="Assinaturas" name="corrections.signatures" ref={register()} />
                      <Switch label="Prazo" name="corrections.deadline" ref={register()} />
                      <Switch label="Custos e Materiais" name="corrections.costsAndMaterials" ref={register()} />
                      <Switch label="Fotos da correção" name="corrections.correctionPictures" ref={register()} />
                    </GridColumn>
                  </Accordion>

                  <FieldRow marginBottom="1rem" />

                  <Accordion titulo="Métricas">
                    <GridColumn size={2} columnGap="1rem" rowGap="1rem">
                      <Switch
                        label="Métricas de cada ocorrencia"
                        name="metrics.metricsForEachOccurrence"
                        ref={register()}
                      />
                      {/* <Switch
                            label="Resumo de todas as ocorrências"
                            name="metrics.summaryOfAllOccurrences"
                            ref={register()}
                          /> */}
                    </GridColumn>
                  </Accordion>
                </Conditional>
                <Conditional when={watch('reportType') === 'excel'}>
                  <Accordion titulo="Período" isRequired>
                    <GridColumn size={1} columnGap="1rem" rowGap="1rem">
                      {watch('reportPeriod') === 'reportOpen' && (
                        <FormGroup label="Abertura da ocorrência" disabled={watch('showOnlyOpenOccurrences')}>
                          <Controller
                            render={props => (
                              <DateRangePicker
                                {...props}
                                data-cy="opening-of-occurrence-date-picker"
                                size="small"
                                placeholder="Seleção obrigatória"
                                disabled={watch('showOnlyOpenOccurrences')}
                              />
                            )}
                            name="period.openingOfOccurrence"
                            control={control}
                            defaultValue={currentSavedFilter?.period?.openingOfOccurrence || ''}
                          />
                        </FormGroup>
                      )}
                      {watch('reportPeriod') === 'reportStart' && (
                        <FormGroup label="Cadastro de correção">
                          <Controller
                            render={props => (
                              <DateRangePicker {...props} size="small" placeholder="Seleção obrigatória" />
                            )}
                            name="period.correctionRegister"
                            control={control}
                            defaultValue={currentSavedFilter?.period?.correctionRegister || ''}
                          />
                        </FormGroup>
                      )}
                      {watch('reportPeriod') === 'reportLimit' && (
                        <FormGroup label="Limite de execução">
                          <Controller
                            render={props => (
                              <DateRangePicker {...props} size="small" placeholder="Seleção obrigatória" />
                            )}
                            name="period.executionLimit"
                            control={control}
                            defaultValue={currentSavedFilter?.period?.executionLimit || ''}
                          />
                        </FormGroup>
                      )}
                    </GridColumn>
                  </Accordion>
                  <FieldRow marginBottom="1rem" />
                  <Accordion titulo="Status" isRequired>
                    <GridColumn size={1} columnGap="1rem">
                      <FormGroup
                        label="Status da ocorrência"
                        disabled={fields?.status?.occurrences?.length <= 0 || watch('showOnlyOpenOccurrences')}
                        isRequired
                      >
                        <Controller
                          render={props => (
                            <MultiSelect
                              {...props}
                              size="small"
                              placeholder="Seleção obrigatória"
                              options={fields?.status?.occurrences}
                              disabled={watch('showOnlyOpenOccurrences')}
                              onChange={value => {
                                props.onChange(value);
                                populateFields('SECOND_COLUMN');
                              }}
                            />
                          )}
                          name="status.occurrences"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                    </GridColumn>
                  </Accordion>
                  <FieldRow marginBottom="1rem" />
                  <Accordion titulo="Correções">
                    <GridColumn size={1} columnGap="1rm">
                      <FormGroup label={'Exibir no relatório:'}>
                        <Controller
                          render={props => (
                            <MultiCheckbox
                              {...props}
                              options={checkBoxOptions}
                              register={register}
                              watch={watch}
                              ref={null}
                              onChange={value => {
                                props.onChange(value);
                              }}
                            />
                          )}
                          name="multiCheckbox"
                          control={control}
                          defaultValue={[]}
                        />
                      </FormGroup>
                    </GridColumn>
                  </Accordion>
                </Conditional>
                <FieldRow marginBottom="1rem" />
              </ReportContainerFields>
            </ReportContainerColumn>
          </Conditional>
        </>
      ),
      propertyContent: 'formulario',
    },
    {
      id: 1,
      title: 'Relatórios gerados',
      content: (
        <>
          <ReportTable
            title="Histórico dos últimos 90 dias:"
            columns={columns}
            data={dataTable}
            updateGeneratedReports={updateGeneratedReports}
          />
        </>
      ),
      propertyContent: 'tabela',
    },
  ];

  const activeTab = useState(tabs[0]);
  const [autoReload, setAutoReload] = useState(tabs[0].id);
  useEffect(() => {
    const intervalUpdate = setInterval(async () => {
      if (fields.generatedReports.some(element => element.status === 1 || element.status == 3) && autoReload === 1) {
        await updateGeneratedReports60S();
      }
    }, 60000);
    return () => clearInterval(intervalUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoReload]);
  const emailNotVerifiedElement = (
    <EmailNotVerifiedWarningContainer>
      <svg width="80" height="88" viewBox="0 0 80 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.75436e-05 30.1139V57.8454C-0.00416577 60.7046 0.739864 63.5143 2.15712 65.9914C3.57437 68.4686 5.61477 70.5256 8.07267 71.9552L31.9273 85.8209C34.3817 87.2485 37.1659 88 40 88C42.8341 88 45.6183 87.2485 48.0727 85.8209L71.9273 71.9552C74.3852 70.5256 76.4256 68.4686 77.8429 65.9914C79.2601 63.5143 80.0042 60.7046 80 57.8454V30.1139C79.9971 27.2617 79.2497 24.4603 77.8328 21.9909C76.4159 19.5216 74.3793 17.471 71.9273 16.0448L48.0727 2.17907C45.6183 0.751537 42.8341 0 40 0C37.1659 0 34.3817 0.751537 31.9273 2.17907L8.07267 16.2481C5.65006 17.6568 3.63231 19.6755 2.21715 22.1062C0.802002 24.5368 0.0379999 27.2962 1.75436e-05 30.1139Z"
          fill="#EAE8E5"
        />
        <path
          d="M58.1797 39.5833C56.293 41.0833 53.9141 42.9167 45.5469 49.0833C43.9062 50.3333 40.8711 53.0833 38 53.0833C35.0469 53.0833 32.0938 50.3333 30.3711 49.0833C22.0039 42.9167 19.625 41.0833 17.7383 39.5833C17.4102 39.3333 17 39.5833 17 40V57C17 59.25 18.7227 61 20.9375 61H55.0625C57.1953 61 59 59.25 59 57V40C59 39.5833 58.5078 39.3333 58.1797 39.5833ZM38 50.3333C39.8867 50.4167 42.5938 47.9167 43.9883 46.9167C54.8984 38.9167 55.7188 38.1667 58.1797 36.1667C58.6719 35.8333 59 35.25 59 34.5833V33C59 30.8333 57.1953 29 55.0625 29H20.9375C18.7227 29 17 30.8333 17 33V34.5833C17 35.25 17.2461 35.8333 17.7383 36.1667C20.1992 38.1667 21.0195 38.9167 31.9297 46.9167C33.3242 47.9167 36.0312 50.4167 38 50.3333Z"
          fill="#AAAAAA"
        />
        <circle cx="56" cy="32" r="13.5" fill="#EC6655" stroke="#EAE8E5" strokeWidth="3" />
        <path
          d="M53.334 24.7439L53.8188 31.3714C53.8188 31.7908 54.0613 32.0005 54.5461 32.0005H57.4552C57.94 32.0005 58.1825 31.7908 58.1825 31.3714L58.6673 24.7439C58.6673 24.5152 58.5865 24.3436 58.4249 24.2292C58.3037 24.0767 58.1421 24.0005 57.94 24.0005H54.0613C53.8592 24.0005 53.6774 24.0767 53.5158 24.2292C53.3946 24.3436 53.334 24.5152 53.334 24.7439Z"
          fill="white"
        />
        <path
          d="M56.0007 34.6675C56.735 34.6675 57.3533 34.938 57.8557 35.4791C58.3968 35.9815 58.6673 36.5998 58.6673 37.3341C58.6673 38.0684 58.3968 38.7061 57.8557 39.2472C57.3533 39.7496 56.735 40.0008 56.0007 40.0008C55.2664 40.0008 54.6287 39.7496 54.0876 39.2472C53.5852 38.7061 53.334 38.0684 53.334 37.3341C53.334 36.5998 53.5852 35.9815 54.0876 35.4791C54.6287 34.938 55.2664 34.6675 56.0007 34.6675Z"
          fill="white"
        />
      </svg>
      <div>Para exportar o relatório é necessário ter um e-mail válido cadastrado no seu perfil.</div>
      <span>Se for preciso peça a ajuda de um administrador ou fale conosco.</span>
      <Button theme="primary" onClick={goback}>
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 5H1" stroke={colors.white} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5 1L1 5L5 9" stroke={colors.white} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        Voltar
      </Button>
    </EmailNotVerifiedWarningContainer>
  );
  const closeAtentionModal = () => {
    setDuplicatedReport(false);
  };
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  return (
    <>
      {isLoading && <Loading />}
      <ReportPage setIsSubMenuOpen={setIsSubMenuOpen}>
        <PageWrapper background={colors.pampas}>
          <HeaderFiltersPage location={pathname} isSubMenuOpen={isSubMenuOpen}>
            <Header>
              <GoBackButton onClick={() => goback()}>
                <svg width="12" height="10" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.75258 0.341509C10.1163 0.757146 10.0741 1.38891 9.6585 1.75259L2.51859 8.00001L9.6585 14.2474C10.0741 14.6111 10.1163 15.2429 9.75258 15.6585C9.3889 16.0742 8.75713 16.1163 8.3415 15.7526L0.341495 8.75259C0.12448 8.5627 0 8.28838 0 8.00001C0 7.71165 0.12448 7.43733 0.341495 7.24744L8.3415 0.247437C8.75713 -0.116245 9.3889 -0.0741276 9.75258 0.341509Z"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 8C0 7.44772 0.447715 7 1 7H23C23.5523 7 24 7.44772 24 8C24 8.55228 23.5523 9 23 9H1C0.447715 9 0 8.55228 0 8Z"
                    fill="black"
                  />
                </svg>
              </GoBackButton>
              <PageHeader title="Relatório de Ocorrências e Correções" />
            </Header>
            <ReportContainerTabs
              tabs={tabs}
              activeTab={activeTab[0].id}
              exportTitle="Exportar Relatório"
              setAutoReload={setAutoReload}
              isPageDisabled={isPageDisabled}
              onSubmit={handleSubmit(onSubmit)}
              currentSavedFilter={currentSavedFilter?.filterName}
              useSavedFilters={openModalSavedFilters}
              cleanSavedFilter={cleanFields}
              saveFilter={saveFilters}
              activeAfterSendEmail={active}
              setActiveAfterSendEmail={setActive}
              //isPageDisabled={isPageDisabled}
              //onBack={goback}
              cleanFields={cleanFields}
              refInputFilterName={register({
                required: true,
              })}
              emailNotVerifiedElement={emailNotVerifiedElement}
              formId={reportName}
              renderFooter={watch('units')?.length > 0}
              //emailNotVerifiedElement={emailNotVerifiedElement}
            >
              {isPageDisabled ? emailNotVerifiedElement : tabs}
            </ReportContainerTabs>
          </HeaderFiltersPage>
        </PageWrapper>
        {/* <InvisibleDownloadLink
          ref={downloadLinkElement}
          href={downloadReport?.href}
          download={downloadReport?.name}
        /> */}
        <Toast toastList={toastList} autoDelete dismissTime={5000} />
        <FilterModal
          options={modalOptions}
          closeModal={() =>
            setModalOptions(() => ({
              open: false,
            }))
          }
          onSelect={onSelectFilter}
          hasDeleteBtn
          onDelete={deleteSavedFilter}
          getData={getModalOptionsData}
        />
        <SendOcurrenceEmailSuccessModal
          hasHeader
          title="Estamos preparando o seu relatório"
          icon={<EmailSuccessIcon />}
          size={{ width: '618px' }}
          open={isSendOcurrenceEmailSuccess}
          closeModal={() => closeModal()}
        >
          <p>
            Assim que estiver pronto, o arquivo ficará disponível na aba de “Relatórios gerados” e enviaremos um aviso
            para o e-mail cadastrado em sua conta.
          </p>
          <Button theme="primary" onClick={() => sendEmail()}>
            Entendi
          </Button>
        </SendOcurrenceEmailSuccessModal>
        <SenderrorEmailSuccessModal
          hasHeader
          title="Não foi possível gerar  relatório."
          icon={<RelSendError />}
          size={{ width: '444px' }}
          open={isSendOcurrenceEmailError500}
          closeModal={() => setIsSendOcurrenceEmailError500(false)}
        >
          <p>
            Ocorreu um erro na emissão do relatório. Tente novamente, por favor. Se o problema persistir, entre em
            contato pelo e-mail suporte@leankeep.com.br{' '}
          </p>
          <Button theme="primary" onClick={() => setIsSendOcurrenceEmailError500(false)}>
            Entendi
          </Button>
        </SenderrorEmailSuccessModal>
        <SenderrorEmailSuccessModal
          hasHeader
          title="Não foi possível gerar  relatório."
          icon={<RelSendError />}
          size={{ width: '526px' }}
          open={isSendOcurrenceEmailError429}
          closeModal={() => setIsSendOcurrenceEmailError429(false)}
        >
          <p>
            O relatório solicitado ultrapassou o limite de 500 ocorrências. Tente novamente alterando os filtros ou
            utilize o formato Excel.
          </p>
          <Button theme="primary" onClick={() => setIsSendOcurrenceEmailError429(false)}>
            Entendi
          </Button>
        </SenderrorEmailSuccessModal>
        <SenderrorEmailSuccessModal
          hasHeader
          title="Não foi possível gerar  relatório."
          icon={<RelSendError />}
          size={{ width: '402px' }}
          open={isSendOcurrenceEmailError422}
          closeModal={() => setIsSendOcurrenceEmailError422(false)}
        >
          <p>Não há ocorrências para filtros aplicados, altere a seleção dos filtros e tente novamente.</p>
          <Button theme="primary" onClick={() => setIsSendOcurrenceEmailError422(false)}>
            Entendi
          </Button>
        </SenderrorEmailSuccessModal>
        <AtentionModal
          hasHeader
          title="O relatório já está sendo gerado"
          icon={<EmailAtentionIcon />}
          size={{ width: '554px' }}
          success={true}
          open={duplicatedReport}
          closeModal={() => setDuplicatedReport(false)}
        >
          <p>Já existe um relatório com esses dados sendo gerado, portanto essa nova requisição foi cancelada.</p>
          <Button theme="primary" onClick={() => closeAtentionModal()}>
            Entendi
          </Button>
        </AtentionModal>
      </ReportPage>
    </>
  );
};

export default Occurrence;
