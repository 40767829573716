import React, { useContext, useEffect, useState } from 'react';
import { Loading } from 'components';
import { GlobalComponentsContext } from 'context/GlobalComponentsContext';
import { HeaderFiltersPage } from 'containers/HeaderFiltersPage';
import { PageWrapper } from 'components/PageWrapper';
import { MobileNavbar } from 'components/MobileNavbar';
//import { Menu } from 'components/Menu';
import { colors } from 'assets/styled/tokens';
import { HomeDashboard } from 'components/HomeDashboard';
import { AdsContainerCarousel } from 'components/AdsConatiner';
import { Bg1080pContainer, FirstLineHomeWrapper } from './styled';
import { ReactComponent as BgImg } from 'assets/icons/1080p-bg-img.svg';
import { NewMenu } from 'components/Menu/NewMenu';
import { AuthContext } from 'context';
import { FiltersContext } from 'context';
import { HomeService } from 'services/main/endpoints/HomeService';
import { Toast } from 'components';
import { createGenericErrorToast } from 'utils/createToast';
export const Home = ({ pathname }) => {
  const { authContext } = useContext(AuthContext);
  const { token } = authContext;
  const { globalComponentsContext /*setGlobalComponentsContext*/ } = useContext(GlobalComponentsContext);
  const { isLoading } = globalComponentsContext;
  const { headerFiltersContext } = useContext(FiltersContext);
  const { headerFilters } = headerFiltersContext;
  const [toastList, setToastList] = useState([]);
  //const authStorage = getLocalAuthStorage();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [charts, setCharts] = useState({
    Atividades: {},
    Ocorrencias: {},
  });
  const populateFields = async () => {
    const date = new Date();
    const Month = date.getMonth() + 1;
    const Year = date.getFullYear();
    return await Promise.all([
      HomeService.getHomeDashAtividades({
        token,
        empresa: headerFilters?.companies?.id,
        unidade: headerFilters?.units?.id,
        GrupoUnidadeId: headerFilters?.unitGroups?.id,
        SubGrupoUnidadeId: headerFilters?.unitSubgroups?.id,
        Month,
        Year,
      }),
      HomeService.getHomeDashOcorrencias({
        token,
        empresa: headerFilters?.companies?.id,
        unidade: headerFilters?.units?.id,
        GrupoUnidadeId: headerFilters?.unitGroups?.id,
        SubGrupoUnidadeId: headerFilters?.unitSubgroups?.id,
        Month,
        Year,
      }),
    ])
      .then(values => ({
        Atividades: values[0]?.data,
        Ocorrencias: values[1]?.data,
      }))
      .catch(() => {
        createGenericErrorToast(setToastList);
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      const data = await populateFields();
      setCharts(prevState => ({
        ...prevState,
        ...data,
      }));
    };
    if (headerFilters.companies) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [headerFilters?.companies?.id, headerFilters?.units?.id]);
  return (
    <>
      {isLoading && <Loading />}
      <PageWrapper background={colors.pampas}>
        <MobileNavbar />
        {/* <Menu /> */}
        <NewMenu setIsSubMenuOpen={setIsSubMenuOpen} />
        <HeaderFiltersPage location={pathname} isSubMenuOpen={isSubMenuOpen}>
          <FirstLineHomeWrapper>
            <HomeDashboard charts={charts} />
            <AdsContainerCarousel />
          </FirstLineHomeWrapper>
          <Bg1080pContainer>
            <BgImg />
          </Bg1080pContainer>
        </HeaderFiltersPage>
      </PageWrapper>
      <Toast toastList={toastList} autoDelete dismissTime={5000} />
    </>
  );
};
