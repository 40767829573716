import { CarouselContainer, SlideItem, ClickContainer } from './styled';
import { register } from 'swiper/element/bundle';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import * as Sentry from '@sentry/react';
register();

export const Carousel = () => {
  const [banners, setBanners] = useState([]);
  const [cookie] = useCookies();
  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch('/static/banners-home.json', {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            mode: 'no-cors',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        let filteredData = data;

        if (cookie?.companies?.id === '7014') {
          filteredData = filteredData.filter(data => !data?.image?.includes('indicacao'));
        }

        const shuffledBanners = filteredData.sort(() => Math.random() - 0.5);
        setBanners(shuffledBanners);
      } catch (error) {
        Sentry.captureException(error);
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, [cookie?.companies?.id]);
  const swiperRef = useRef();
  const history = useHistory();
  useEffect(() => {
    const swiperContainer = swiperRef.current;
    const hasMultipleItems = banners.length > 1;
    const params = {
      navigation: hasMultipleItems,
      pagination: { clickable: true },
      autoplay: {
        delay: 15000,
      },
      loop: true,
      injectStyles: [
        `
        .swiper-button-next{
          margin-right: 10px;
        }
        .swiper-button-prev{
          margin-left: 10px;
        }
          .swiper-button-next,
          .swiper-button-prev {
            background-color: #fff;
            border-radius: 100%;
            /*border: 1px solid black;*/
            color: #1e1e1e;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .swiper-button-next svg,
          .swiper-button-prev svg{
            width:7px;
            height:14px;
          }
          .swiper-pagination-bullet{
            width: 12px;
            height: 12px;
            background-color: #fff;
            border: 1px solid #AAAAAA;
            box-sizing: border-box;
            opacity: 1;
          }
          .swiper-pagination-bullet-active {
            opacity: var(--swiper-pagination-bullet-opacity, 1);
            background-color: #AAAAAA !important;
        }import { *asSentry } from '@sentry/react';

          .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
            opacity: 1;
          }
          .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
            bottom: var(--swiper-pagination-bottom, 0);
            top: var(--swiper-pagination-top,auto);
            left: 50%;
            background-color: #fff;
            transform: translateX(-50%);
            border: none;
            border-bottom: none;
            width: fit-content;
            height: 24px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            :host{
              height:100%;
            }
      `,
      ],
    };
    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, [banners?.length]);
  const handleClick = (e, url, newTab) => {
    e.preventDefault();
    if (newTab) {
      window.open(url, '_blank');
    } else {
      history.push(url);
    }
  };
  return (
    <CarouselContainer>
      <swiper-container
        ref={swiperRef}
        init="false"
        style={{
          height: '100%',
        }}
      >
        {banners.map(item => (
          <swiper-slide key={item.id}>
            <ClickContainer onClick={e => handleClick(e, item.url, item.newTab)}>
              <SlideItem src={item.image} alt="teste" />
            </ClickContainer>
          </swiper-slide>
        ))}
      </swiper-container>
    </CarouselContainer>
  );
};
