import React from 'react';

import { UserMenu } from 'components/UserMenu';

import { URLS } from 'constants/urls';

import {
  StyledHeader,
  StyledHeaderChildren,
  StyledHeaderActions,
  StyledShowUserMenu,
  RefereeButton,
  RightSideHeader,
} from './styled';
import { useCookies } from 'react-cookie';

const Header = ({ children }) => {
  const [cookie] = useCookies();
  return (
    <StyledHeader>
      <StyledHeaderChildren>{children}</StyledHeaderChildren>
      <RightSideHeader>
        {cookie?.companies?.id != '7014' && (
          <RefereeButton
            onClick={() =>
              window.open(
                'https://leankeep.com/indica-leankeep?bl_ref=PNDPZ&utm_source=software&utm_medium=menu&utm_campaign=indicacao-clientes',
              )
            }
          >
            Indique e Ganhe R$500
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
              <g clip-path="url(#clip0_320_478)">
                <path
                  d="M4.83691 1.74688L5.72051 3.25H5.6875H3.85938C3.29824 3.25 2.84375 2.79551 2.84375 2.23438C2.84375 1.67324 3.29824 1.21875 3.85938 1.21875H3.91523C4.29355 1.21875 4.64648 1.41934 4.83691 1.74688ZM1.625 2.23438C1.625 2.6 1.71387 2.94531 1.86875 3.25H0.8125C0.363086 3.25 0 3.61309 0 4.0625V5.6875C0 6.13691 0.363086 6.5 0.8125 6.5H12.1875C12.6369 6.5 13 6.13691 13 5.6875V4.0625C13 3.61309 12.6369 3.25 12.1875 3.25H11.1312C11.2861 2.94531 11.375 2.6 11.375 2.23438C11.375 1.00039 10.3746 0 9.14062 0H9.08477C8.2748 0 7.52324 0.429102 7.11191 1.12734L6.5 2.1709L5.88809 1.12988C5.47676 0.429102 4.7252 0 3.91523 0H3.85938C2.62539 0 1.625 1.00039 1.625 2.23438ZM10.1562 2.23438C10.1562 2.79551 9.70176 3.25 9.14062 3.25H7.3125H7.27949L8.16309 1.74688C8.35605 1.41934 8.70644 1.21875 9.08477 1.21875H9.14062C9.70176 1.21875 10.1562 1.67324 10.1562 2.23438ZM0.8125 7.3125V11.7812C0.8125 12.4541 1.3584 13 2.03125 13H5.6875V7.3125H0.8125ZM7.3125 13H10.9688C11.6416 13 12.1875 12.4541 12.1875 11.7812V7.3125H7.3125V13Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_320_478">
                  <rect width="13" height="13" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </RefereeButton>
        )}
        <StyledShowUserMenu>
          <StyledHeaderActions>
            <a href={URLS.movidesk} target="_blank" rel="noopener noreferrer">
              Central de Ajuda
            </a>
            <UserMenu />
          </StyledHeaderActions>
        </StyledShowUserMenu>
      </RightSideHeader>
    </StyledHeader>
  );
};

export { Header };
